<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Campaigns</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <template v-if="!loadingCampaigns">
              <div class="table-responsive mb-0">
                <b-table
                  :items="campaigns"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :current-page="datatable.queries.page"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | moment('YYYY-MM-DD HH:mm:ss') }}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <router-link
                      :to="{
                        name: 'business.campaigns.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                      variant="danger"
                      size="sm"
                      title="Delete"
                      class="ml-1"
                      :disabled="loadingRemove === data.item.id"
                      @click="destroy(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ campaigns.length }} campaigns
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="campaigns.length"
                        :per-page="datatable.queries.per_page"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete campaign">
      <p v-if="selectedCampaign"
        >Are you sure you want to delete campaign "{{
          selectedCampaign.title
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'title', sortable: true },
          { key: 'description', sortable: true },
          { key: 'created_at', sortable: true },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedCampaign: null,
      loadingCampaigns: false,
      loadingImpersonate: false,
      loadingRemove: null,
    }
  },

  computed: {
    campaigns() {
      let campaigns = this.$store.getters['campaign/all'] || []
      let query = (this.datatable.queries.search || '')
        .trim()
        .toLocaleLowerCase()

      if (query) {
        campaigns = campaigns.filter(
          (i) =>
            i.name.toLocaleLowerCase().search(query) !== -1 ||
            i.owner.email.toLocaleLowerCase().search(query) !== -1
        )
      }

      return campaigns
    },
  },

  mounted() {
    this.getcampaigns()
  },

  methods: {
    getcampaigns() {
      this.loadingCampaigns = true

      this.$store
        .dispatch('campaign/getAll')
        .then(() => {
          this.loadingCampaigns = false
        })
        .catch(() => {
          this.loadingCampaigns = false
        })
    },

    destroy(campaign) {
      this.showDeleteModal = true
      this.selectedCampaign = campaign
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedCampaign.id
      this.$store
        .dispatch('campaign/remove', this.selectedCampaign.id)
        .then(() => {
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
